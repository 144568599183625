import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Svg from "../Svg";

import { ReactComponent as Forecast } from "../../assets/forecast.svg";
// import { ReactComponent as Weather } from "../../assets/weather.svg";
import { turquoise } from "../../sass/colors";

import PersonContext from "../../util/PersonContext";

function ForecastNav() {
  const { person } = useContext(PersonContext);
  return (
    <div className={"has-dropright"}>
      <div className={"dropright-header"}>
        <Svg size={"1.5rem"} color={"white"} Component={Forecast} />
        <span>Prévisions</span>
      </div>
      <div className="dropright">
        {/*<div className={"nav-section"}>*/}
        {/*  <div className={"nav-section-header"}>*/}
        {/*    <Svg size={"1.5rem"} color={turquoise} Component={Weather} />*/}
        {/*    <h1>Météorologie</h1>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={"nav-section"}>
          <div className={"nav-section-header"}>
            <Svg size={"1.5rem"} color={turquoise} Component={Forecast} />
            <h1>Hydrologie</h1>
          </div>
          <Link
            className={"dropright-item"}
            to={"pegase/public"}
            onClick={(e) => e.target.blur()}
          >
            Prévision hydro Super-Ensemble
          </Link>
          {person && person.isHydroMeteo() && (
            <>
              <Link
                className="dropright-item"
                to={`/pegase/single`}
                onClick={(e) => e.target.blur()}
              >
                Prévision hydro 25 membres
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/by-weather-init`}
                onClick={(e) => e.target.blur()}
              >
                Prévision hydro par modèle météo
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/multiple`}
                onClick={(e) => e.target.blur()}
              >
                Prévision hydro par modèle hydro
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/ensemble`}
                onClick={(e) => e.target.blur()}
              >
                Prévision hydro Super-Ensemble (Hydro Météo)
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/summary`}
                onClick={(e) => e.target.blur()}
              >
                Tableau récapitulatif
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/evaluation-free`}
                onClick={(e) => e.target.blur()}
              >
                Évaluation libre des prévisions
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/evaluation-window`}
                onClick={(e) => e.target.blur()}
              >
                Évaluation des prévisions par fenêtre d'événement
              </Link>
              <Link
                className="dropright-item"
                to={`/pegase/evaluation-multi-window`}
                onClick={(e) => e.target.blur()}
              >
                Évaluation multi-fenêtre d'événement
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForecastNav;
