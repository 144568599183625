const test = {
  selfUrl: "http://localhost:3333",
  apiUrl: "http://localhost:3000",
  qcVortexUrl: "http://localhost/qcvortex",
};
const development = {
  selfUrl: "http://localhost:3333",
  apiUrl: "http://localhost:3000",
  qcVortexUrl: "http://nowcast.quebecvortex.com",
};
const production = {
  selfUrl: "https://syshydro2.ca",
  apiUrl: "https://api.syshydro2.ca",
  qcVortexUrl: "http://nowcast.quebecvortex.com",
};

let env;

switch (process.env.NODE_ENV) {
  case "production":
    env = production;
    break;
  case "test":
    env = test;
    break;
  case development:
  default:
    env = development;
}

export default env;
