import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import Checkbox from "../../components/Checkbox";
import DatePicker from "../../components/DatePicker";
import MultiSelect from "../../components/MultiSelect";
import Select from "../../components/Select";
import Waiting from "../../components/Waiting";

import PersonContext from "../../util/PersonContext";
import scribe from "../../util/scribe";
import xhr from "../../services/xhr";
import ClientMap from "../maps/ClientMap";

const datify = (client) => {
  for (const service of ["stationsService", "weatherService", "iceService"]) {
    for (const border of ["beginning", "end"]) {
      if (client?.[service]?.[border])
        client[service][border] = new Date(client[service][border]);
    }
  }
};

function Client() {
  const [loading, setLoading] = useState(true);
  const [persons, setPersons] = useState([]);
  const [client, setClient] = useState(null);
  const [errors, setErrors] = useState({});
  const { person } = useContext(PersonContext);
  let { id } = useParams();

  useEffect(() => {
    if (person?.isAdmin() && id) {
      const promises = [];
      promises.push(xhr.get("/persons"));
      promises.push(xhr.get("/clients/" + id));
      Promise.all(promises)
        .then(([ps, c]) => {
          setPersons(
            ps.map((p) => ({
              id: p.id,
              name: `#${p.id} ${p.firstName} ${p.lastName}`,
            }))
          );
          datify(c);
          setClient(c);
          setLoading(false);
        })
        .catch(scribe.error);
    }
  }, [id, person]);

  if (!person?.isAdmin()) return null;

  if (loading) return <Waiting />;

  const { name } = client;

  const handleSave = async () => {
    try {
      const cp = structuredClone(client);
      delete cp.id;
      delete cp.name;
      delete cp.stations;
      delete cp.iceGraphs;
      delete cp.geom;
      delete cp.apiKey;
      const c = await xhr.put("/clients/" + client.id, cp);
      datify(c);
      setClient(c);
      toast.success("Mise à jour réussie!");
    } catch (e) {
      toast.error(
        "Erreur lors de la mise à jour, envoyez le contenu de ctrl+shift+i > Console à votre administratrice système"
      );
      console.error(e?.response || e);
    }
  };

  const handleChange = (name, value) => {
    const cp = structuredClone(client);
    const err = structuredClone(errors);
    if (name.includes(".")) {
      const [service, field] = name.split(".");
      cp[service][field] = value;
      if (cp[service].service) {
        if (!cp[service].beginning) {
          err[`${service}Beginning`] =
            "La date de début est requise si l'abonnement est actif";
        } else {
          err[`${service}Beginning`] = null;
        }
        if (!cp[service].end) {
          err[`${service}End`] =
            "La date de fin est requise si l'abonnement est actif";
        } else {
          err[`${service}End`] = null;
        }
      } else {
        err[`${service}Beginning`] = null;
        err[`${service}End`] = null;
      }
    } else {
      cp[name] = value;
    }
    setClient(cp);
    setErrors(err);
  };

  const globallyValid = Object.values(errors).filter((v) => !!v).length === 0;

  return (
    <div className={"content"}>
      <h1 className={"title is-1"}>
        #{id} {name}
      </h1>
      <div className={"columns"}>
        <div className={"column"}>
          <h2 className={"title is-2"}>Personnes ayant accès</h2>
          <MultiSelect
            name={"employees"}
            options={persons}
            value={client.employees}
            label={"Personnes associées"}
            onChange={handleChange}
          />
          <h2 className={"title is-2"}>Responsable</h2>
          <Select
            label={"Responsable"}
            name={"pocs"}
            options={persons}
            value={client.pocs?.[0]}
            onChange={(name, val) => handleChange(name, [val])}
          />
          <h2 className={"title is-2"}>Enveloppe géographique</h2>
          <ClientMap envelope={client?.geojson} />
        </div>
        <div className={"column"}>
          <h2 className={"title is-2"}>Services</h2>
          <label className={"label"}>Stations télémétriques</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 104 }}>
            <div className={"column"}>
              <Checkbox
                name={"stationsService.service"}
                value={!!client?.stationsService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.stationsService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"stationsService.beginning"}
                  value={client.stationsService.beginning}
                  onChange={handleChange}
                  error={errors.stationsServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.stationsService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"stationsService.end"}
                  value={client.stationsService.end}
                  onChange={handleChange}
                  error={errors.stationsServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <p>
            Pour modifier les stations auxquelles un client a accès, utiliser
            sos-hydro-le-vieux.
          </p>
          <label className={"label"}>S&P Météo</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 144 }}>
            <div className={"column"}>
              <Checkbox
                name={"weatherService.service"}
                value={!!client?.weatherService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.weatherService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"weatherService.beginning"}
                  value={client.weatherService.beginning}
                  onChange={handleChange}
                  error={errors.weatherServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.weatherService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"weatherService.end"}
                  value={client.weatherService.end}
                  onChange={handleChange}
                  error={errors.weatherServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <label className={"label"}>S&P Hydro/glacio</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 144 }}>
            <div className={"column"}>
              <Checkbox
                name={"iceService.service"}
                value={!!client?.iceService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.iceService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"iceService.beginning"}
                  value={client.iceService.beginning}
                  onChange={handleChange}
                  error={errors.iceServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.iceService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"iceService.end"}
                  value={client.iceService.end}
                  onChange={handleChange}
                  error={errors.iceServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <p>
            Pour modifier les graphiques de glace auxquels un client a accès,
            utiliser sos-hydro-le-vieux.
          </p>
        </div>
      </div>
      <button
        className={`button is-primary is-narrow`}
        onClick={handleSave}
        disabled={!globallyValid}
      >
        Sauvegarder
      </button>
    </div>
  );
}

export default Client;
